import React, {useState} from 'react';

import sendMail from '../functions/sendMail';

import Modal from '../components/termModal';

import validateInput from '../functions/validateInput';

const Contact = (props) => {
    const [modal_state, setState] = useState(false);
    const [submit_loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [belong, setBelong] = useState('');
    const [tel, setTel] = useState('');

    const handleInput = (type) => (e) => {
        switch (type) {
        case 'name':
            setName(e.target.value);
            break;
        case 'email':
            setEmail(e.target.value);
            break;
        case 'message':
            setMessage(e.target.value);
            break;
        case 'belong':
            setBelong(e.target.value);
            break;
        case 'tel':
            setTel(e.target.value);
            break;
        default:
        }
    };

    const onMailSend = () => {
        const {validation, log} = validateInput({email, name});

        if (!validation) {
            alert(log);
            return;
        }
        controlModal();
    };

    const onAgreeTerm = async () => {
        setLoading(true);
        const {success, log} = await sendMail({message, email, name, belong, tel});

        if (!success) {
            alert(log);
            return;
        }

        setName('');
        setEmail('');
        setBelong('');
        setTel('');
        setMessage('');

        controlModal();
        alert(log);
        setLoading(false);
    };

    const controlModal = () => {
        setState(!modal_state);
    };

    return (
        <div id='contact'>
            <div>
                {
                    modal_state ?
                        <Modal
                            data={props.modal}
                            controlModal={controlModal}
                            onAgreeTerm={onAgreeTerm}
                            closeModal={controlModal}
                            loading={submit_loading}
                        /> :
                        undefined
                }
                <div className="container">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="section-title">
                                <h2>CONTACT US</h2>
                                <p>{props.data.title.subtitle}</p>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={`${props.data.title.name} *`}
                                            required="required"
                                            value={name}
                                            onChange={handleInput('name')}
                                        />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={props.data.title.belong}
                                            value={belong}
                                            onChange={handleInput('belong')}
                                        />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder={`${props.data.title.email} *`}
                                            required="required"
                                            value={email}
                                            onChange={handleInput('email')}
                                        />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="tel"
                                            className="form-control"
                                            placeholder={props.data.title.phone}
                                            value={tel}
                                            onChange={handleInput('tel')}
                                        />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <textarea
                                    className="form-control"
                                    rows="4"
                                    placeholder={`${props.data.title.content} *`}
                                    value={message}
                                    onChange={handleInput('message')}
                                />
                                <p className="help-block text-danger"></p>
                            </div>
                            <div id="success"></div>
                            <div className='col-md-12  contact-button-wrapper'>
                                <button
                                    className="btn btn-custom btn-lg"
                                    onClick={onMailSend}
                                >
                                    {props.data.title.send}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-md-offset-1 contact-info">
                        <div className="contact-item">
                            <h3>Contact Info</h3>
                            <p>
                                <span>
                                    <i className="fa fa-map-marker" />
                                    {props.data.title.address}
                                </span>
                                {props.data ? props.data.address : 'loading'}
                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-phone" />
                                    {props.data.title.tel}
                                </span>
                                {props.data ? props.data.phone : 'loading'}
                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-envelope-o"/>
                                    {props.data.title.email}
                                </span>
                                {props.data ? props.data.email : 'loading'}
                            </p>
                        </div>
                    </div>
                    {/* <div className="col-md-12">
                        <div className="row">
                            <div className="social">
                                <ul>
                                    <li>
                                        <a
                                            href={props.data ? props.data.facebook : '/'}
                                            target='_blank'
                                        >
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={props.data ? props.data.twitter : '/'}
                                            target='_blank'
                                        >
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={props.data ? props.data.youtube : '/'}
                                            target='_blank'
                                        >
                                            <i className="fa fa-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Contact;
