import {mail_api} from '../configs/strings';

const requestSendEmail = (data) => new Promise(async (resolve, reject) => {
    try {
        console.log(data);
        await fetch(mail_api, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            mode: 'no-cors',
            body: JSON.stringify(data),
        });

        resolve();
    } catch (err) {
        console.log(err);
        reject(err);
    }
});

export default requestSendEmail;
