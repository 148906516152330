import React from 'react';

import {recruit_url} from '../configs/strings';

const coreValue = (props) => {
    const {data, title} = props;
    const openNewTab = () => {
        window.open(recruit_url);
    };

    return (
        <div id="services" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>CORE VALUE</h2>
                    <p>{title.description}</p>
                </div>
                <div className="row">
                    {data.map((d, i) =>
                        <div className="col-md-3" key={String(i)}>
                            <img className='feature-icon' src={d.icon}/>
                            <div className="service-desc">
                                <h3>{d.name}</h3>
                                <p>{d.text}</p>
                            </div>
                        </div>,
                    )}
                </div>
                <div className='quality-wrapper'>
                    <div className='quality-title'>{title.HR}</div>
                    <div className='quality-content'>{title.HR_description}</div>
                </div>
                <div className='btn btn-custom btn-lg' onClick={openNewTab}>{title.apply}</div>
            </div>
        </div>
    );
};

export default coreValue;
