import {hook_url} from '../configs/strings';

const hookToSlack = (data) => new Promise(async (resolve, reject) => {
    try {
        const {name, email, tel, belong, message} = data;

        const payload = {
            fallback: '홈페이지로부터 온 연락...',
            pretext: '홈페이지로 부터 온 연락...',
            color: '#00d5d9',
            fields: [
                {
                    title: '이름',
                    value: name,
                },
                {
                    title: '이메일',
                    value: email,
                },
                {
                    title: '소속',
                    value: belong,
                },
                {
                    title: '연락처',
                    value: tel,
                },
                {
                    title: '메세지',
                    value: message,
                },
            ],
        };

        await fetch(hook_url,
            {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                mode: 'no-cors',
                body: JSON.stringify(payload),
            },
        );

        resolve();
    } catch (err) {
        reject(err);
    }
});

export default hookToSlack;
