import React, {useEffect} from 'react';

import Header from '../components/header';
import ImageDescription from '../components/imageDescription';
import CoreValue from '../components/coreValue';
import Timeline from '../components/timeline';
import Team from '../components/team';
import Media from '../components/media';

const About = ({data}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <React.Fragment>
            <Header data={data.About} id='overlay-team' />
            <ImageDescription data={data.About} />
            <CoreValue data={data.Services} title={data.Core_Value} />
            <Timeline data={data.Timeline} title={data.Timeline_Title} />
            <Team data={data.Team} title={data.Team_Title} />
            <Media data={data.Media} />
        </React.Fragment>
    );
};

export default About;
