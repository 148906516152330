import React from 'react';
import {Link} from 'react-router-dom';

const Navigation = ({handleLocalization}) => {
    return (
        <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
            <div className='container'>
                <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1"
                >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                <div className='navbar-left'>
                    <Link className='navbar-header' to='/'>
                        <img
                            className='nav-logo'
                            src={require('../assets/logo_side.png')}
                        />
                    </Link>
                    <div className='localization'>
                        <Link to='/'>
                            <button onClick={handleLocalization('ko')}>KR</button>
                        </Link>
                        <div className='divider' />
                        <Link to='/'>
                            <button onClick={handleLocalization('en')}>EN</button>
                        </Link>
                    </div>
                </div>
                <div className='collapse navbar-collapse' id='bs-example-navbar-collapse-1'>
                    <ul className='nav navbar-nav navbar-right'>
                        <li><Link to='/' className='page-scroll'>Home</Link></li>
                        <li><Link to='/about' className='page-scroll'>About Us</Link></li>
                        <li><Link to='/contact' className='page-scroll'>Contact</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
