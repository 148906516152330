import React from 'react';
import {Link} from 'react-router-dom';

const media = (props) => {
    const onMediaDownload = () => {
        setTimeout(() => {
            alert(props.data.action);
        });
    };

    return (
        <div id="services" className="text-center">
            <div className="container">
                <div className="section-title">
                    <p className='media-text'>{props.data.content_1}</p>
                    <p className='media-text'>{props.data.content_2}</p>
                </div>
                <Link
                    to='/contact'
                    onClick={onMediaDownload}
                >
                    <div className='btn btn-custom btn-lg media'>{props.data.button}</div>
                </Link>
            </div>
        </div>
    );
};

export default media;
