import React, {useEffect} from 'react';

import _Contact from '../components/contact';

const Contact = ({data}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <React.Fragment>
            <div className='navbar-padding' />
            <_Contact data={data.Contact} modal={data.Term} />
        </React.Fragment>
    );
};

export default Contact;
