import React from 'react';

const timeline = (props) => {
    const data = props.data || [];
    return (
        <div id='timeline' className='text-center'>
            <div className='container'>
                <div className="col-md-8 col-md-offset-2 section-title">
                    <h2>HISTORY</h2>
                    <p>{props.title}</p>
                </div>
                <div className='timeline-container col-md-offset-3'>
                    <div className='timeline-line' />
                    <div className='contents'>
                        {
                            data.map((v, i) => {
                                return (
                                    <li className='timeline-element' key={String(i)}>
                                        <h5>{v.date}</h5>
                                        <p className='timeline-line-content'>{v.content}</p>
                                    </li>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default timeline;
