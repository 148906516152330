/* eslint-disable max-len */
const Header = {
    title: 'CEEDUP',
    subtitle: 'CEED Everywhere, UP Every Time, CEED UP!',
};

const Core_Tech_Title = '핵심 기술';

const Core_Tech = [
    {
        name: '인공지능 사물인터넷',
        description: 'AIoT',
        icon: 'img/icons/ai.svg',
    },
    {
        name: '엣지 컴퓨팅',
        description: 'EDGE COMPUTING',
        icon: 'img/icons/circuits.svg',
    },
    {
        name: '초저전력 디바이스',
        description: 'ULTRA LOW POWER DEVICE',
        icon: 'img/icons/electricity.svg',
    },
    {
        name: '센서 데이터 분석',
        description: 'SENSOR DATA ANALYSIS',
        icon: 'img/icons/settings.svg',
    },
    {
        name: 'IoT 통합 모니터링 시스템',
        description: 'INTEGRATED IoT MONITORING',
        icon: 'img/icons/vibration.svg',
    },
];

const Introduction = '씨드업은 사회문제를 해결하기 위해 기술 기반의 혁신을 실현하는 기업입니다.';

const Heritage_Monitor = {
    title: '헤리티지 모니터',
    subtitle: '문화재 통합 관리 솔루션',
    main_description: 'AIoT 기술과 문화재 관리의 융합',
    description: 'AIoT 기술 기반의 모니터링 시스템으로 대한민국 곳곳의 관리 소홀 문화재를 통합 관리하는 솔루션입니다.',
    process: 'IoT 디바이스 - AI 분석 - 실시간 모니터링',
    process_highlight: '문화재 관리',
    functions: [
        {
            title: '저전력 무선 디바이스',
            icon: 'img/icons/ai.svg',
            element_1: '초저전력 설계로 무선 디바이스를 구현했습니다.',
            element_2: '별도의 통신/전력공사 없이 간단한 설치가 가능합니다.',
            element_3: '태양광 패널로 에너지 자립이 가능합니다.',
        },
        {
            title: 'AIoT기반 이상진동 감지',
            icon: 'img/icons/electricity.svg',
            element_1: '산간오지에서도 사각지대 없는 전방위적 관리가 가능합니다.',
            element_2: 'AI 기반 분석을 통해 관리효율을 높였습니다.',
            element_3: '수집된 데이터를 통해 실시간 범죄/재난을 감지합니다.',
        },
        {
            title: '문화재 관리 효율화',
            icon: 'img/icons/analysis.svg',
            element_1: '365일 24시간 언제. 어디서든 웹/앱을 통해 실시간 모니터링이 가능합니다.',
            element_2: '사용자 중심의 보고서를 통해 문화재 상태를 파악 가능합니다.',
            element_3: 'AIoT를 활용한 체계적 문화재 관리가 가능합니다.',
        },
    ],
};

const About = {
    img: 'img/icons/logo_under.png',
    title: 'ABOUT US',
    description_title: 'VISION',
    paragraph_1: 'CEED는 라틴어로 \"go(가다)\"라는 뜻입니다. 씨드업은 언제, 어디서나 성장하고 나아가자는 사명을 가지고',
    paragraph_2: '진동데이터와 IT기술을 융합하여 사회문제를 해결하고자 합니다.',
};

const Core_Value = {
    description: '씨드업이 추구하는 핵심가치입니다.',
    HR: '인재상',
    HR_description: '문제해결을 위해 주도적으로 진화하는 인재',
    apply: '채용 공고 보기',
};

const Services = [
    {
        icon: 'img/icons/heart.svg',
        name: '공감',
        text: '사회문제에 대한 공감을 바탕으로 구체적인 문제의식을 이끌어냅니다.',
    },
    {
        icon: 'img/icons/innovation.svg',
        name: '혁신',
        text: '문제의 본질을 해결하기 위해 새로운 결합을 통한 혁신을 만듭니다.',
    },
    {
        icon: 'img/icons/star.svg',
        name: '전문성',
        text: '전문성을 바탕으로 고도화된 지식을 가장 효율적으로 해결책에 연결합니다.',
    },
    {
        icon: 'img/icons/sport.svg',
        name: '실현',
        text: '지속적인 실천을 통해 혁신을 실현합니다.',
    },
];

const Timeline_Title = '씨드업이 걸어온 길';

const Timeline = [
    {
        date: '2018. 11',
        content: 'IoT 기술기반 문화재 관리 디바이스 1차 설계',
    },
    {
        date: '2019. 4',
        content: '헤리티지 모니터 MVP 제작 및 실험',
    },
    {
        date: '2019. 7',
        content: '2019 KT Social Change Maker KT 이사장상 수상',
    },
    {
        date: '2019. 8',
        content: '2019 교육부장관 인증 창업 유망팀 선정',
    },
    {
        date: '2019. 9',
        content: '2019 소셜벤처 경연대회 전국권 SK 혁신상 수상',
    },
    {
        date: 'ㅤ',
        content: 'ICT 스마트 디바이스 공모전 수상',
    },
    {
        date: '2019. 11',
        content: '2019 C-STAR Pitching 연말 결선 센터장상 수상',
    },
    {
        date: 'ㅤ',
        content: '2019 문화데이터 경진대회 위원장상 수상',
    },
    {
        date: 'ㅤ',
        content: '(주)씨드업 법인 설립',
    },
    {
        date: '2020. 1',
        content: '헤리티지 모니터 양산형 시제품 제작',
    },
    {
        date: '2020. 2',
        content: '연구개발전담부서 설립',
    },
    {
        date: 'ㅤ',
        content: '기술보증기금 벤처인증',
    },
];

const Team_Title = '씨드업의 위대하고 엄격한 구성원들';

const Team = [
    {
        img: 'img/team/hdh_a.png',
        img_b: 'img/team/hdh_b.png',
        name: '한동훈',
        job: 'Operator',
        info: '천방지축 어리둥절 빙글빙글 돌아가는 동훈님의 하루♬\n\"한번 사는 인생 하고 싶은 일 하고 살자\"라는 신조를 가슴에 품고 살아가는 동훈님은 얼마전 또 다른 \"하고 싶은 일\"을 찾아냈어요. 바로 동료에게 최고의 동기부여를 선사하고 최상의 퍼포먼스를 끌어내는 일이랍니다. 이 멋진 재미를 포기할 수는 없죠! 마흔 살까지 재미를 마음껏 즐기며 커리어를 쌓을 예정입니다만, 마흔 살부터는 요리하고, 사람들에게 맛있는 요리를 선사하며 여유롭게 살 작정을 하고 있어요. 상상만 해도 기대되는, 그야말로 멋드러진 인생이에요',
    },
    {
        img: 'img/team/wasy_a.png',
        img_b: 'img/team/wasy_b.png',
        name: '안소영',
        job: 'Manager',
        info: '언젠가 내 이름을 건 극장을 만들겠다는 포부를 가진 연극쟁이 소영님. 누구보다 연극을 사랑하지만 거짓말은 못하겠다는 모순을 견디며 살아가고 있어요. 지금은 밤을 새워 대본을 쓰던 손으로 서류를 써내려가고, 낮을 불태워 연극을 만들던 뜨거운 마음으로 매일 출근하고 있답니다. 스트레스 받을 때 빵을 만든다는 소영님의 또다른 모순은 바로 빵을 먹는 건 좋아하지 않는다는 것! 빵을 맛있게 먹어주는 씨드업 팀원들에게 늘 감사하는 마음을 가지고 있대요. 삶이라는 무대 위를 활보하는 소영님을 응원해주세요',
    },
    // {
    //     img: 'img/team/100_a.png',
    //     img_b: 'img/team/100_b.png',
    //     name: '백광록',
    //     job: 'FW Engineer',
    //     info: '\"운동하기\"다짐 경력 7년 차, \"일찍 일어나는 새가 되기\"다짐 경력 17년 차\n프로다짐러 광록님은 칠하우스 뮤직과 사랑에 빠진 감성의 제왕이랍니다. 사랑에 죽고 정의에 사는 감성 충만한 삻을 살고 있지만, \"에러는 S/W 문제가 아니다. 내 문제다.\"라는 명언을 남긴 멋진 개발자이기도 하죠. 아주 믿음직스러운, 우리 씨드업의 없어선 안될 인재에요. 일과 감성을 모두 놓칠 수 없는 광록님의 최근 삶의 낙은 넷플릭스라고 하네요. 역시 개발로 뜨거워진 머리는 좀비로 식혀주는 게 제맛인 걸까요?',
    // },
    // {
    //     img: 'img/team/gd_a.png',
    //     img_b: 'img/team/gd_b.png',
    //     name: '박기덕',
    //     job: 'Frontend Developer',
    //     info: '희노애락, 기덕님에게는 어울리지 않는 단어죠. 감정 기복률 土5%만을 허용하는 기덕님은 탄력 근무제를 운영하는 씨드업에서 가장 일찍일어나는 새를 담당하고 있어요. 언제나 그 자리에서, 그 모습으로 개발을 하는 기덕님을 보며 모두가 안정감을 느낀답니다. 우리의 정신적 지주라고나 할까요? 심지어 퇴근 후 개인시간에 자기계발에 집중하는 숨은 노력가랍니다. 조용히 꺼지지 않는 촛불처럼, 용의주도한 에너자이저 기덕님의 앞날이 기대되는 건 당연지사 명약관화 빼박캔트죠',
    // },
    {
        img: 'img/team/asy_a.png',
        img_b: 'img/team/asy_b.png',
        name: '안상용',
        job: 'Founder',
        info: '고고학을 공부한 상용님은 전세계를 누비며 보물을 모으는 인디아나 존스가 되려고 했어요. 하지만 세계에 첫 발을 내딛을 첫 이동수단으로 푸드트럭을 선택하고는, 그만 스타트업의 매력에 홀라당 빠져버렸죠. 지금은 매일매일 커피의 힘을 빌려 씨드업을 이끌어나가고 있어요. 밤낮을 기다려 만난 최고의 동료들과 함께 말이에요! 혼자만의 힘으로는 헤쳐나가기 힘든 스타트업 세계를 정복하기 위해 모두의 힘을 빌려 발걸음을 내딛는 상용님. 오늘도 빌리고, 모으고, 줍고, 끌어오고, 만들고... 아무튼 어제보다 나은 내일을 향해 힘차게 나아가고 있답니다',
    },
    // {
    //     img: 'img/team/ksm_a.png',
    //     img_b: 'img/team/ksm_b.png',
    //     name: '김상민',
    //     job: 'FW Engineer',
    //     info: '상민님은 자타공인 우리의 엄격한 관리자에요. 가끔은 내로남불을 시전하지만, 마음속에는 따스함을 지닌 외강내유의 소유자입니다. 세상 쿨한 표정을 한 상민님이 사실은 누구보다 오래 회사에 남아 개발에 몰두하는 열정맨이라는 걸 모르는 사람은 없을거에요. 아닌척, 모르는척, 잔소리하며 씨드업 팀원을 챙기는 엄마같은 존재랍니다. 검은 머리 외국인이 되겠다며 큰소리 치는 여행광답게 자유자재로 구사하는 외국어도 한가득인 재능꾼이죠.\n 다만 서울말은 못해요(쉿)',
    // },
    {
        img: 'img/team/hjm_a.png',
        img_b: 'img/team/hjm_b.png',
        name: '한지민',
        job: 'Archaeologist',
        info: '차분한 원칙주의자 지민님의 가장 큰 행복은 구체적인 계획을 훌륭히 달성해 나가는 것이에요. 가끔은 몽상가를 꿈꾸기도 하지만, 결국은 실제적인 경험과 지식을 좇게되는 못말리는 고고학 덕후죠. 좌우명인 \"넓지 않아도 좁고 깊게\"는 지민님이 사랑하는 것들에 집중하게 해주는 원동력이 되어 준답니다. 지적으로 깊은 사람이 되기를 바라는, 하지만 이미 그보다 깊은 마음을 가진 지민님을 누구나 좋아하게 될거에요.\n일관성 있는 차분함을 자랑하는 지민님을 만나게 되면 신나게 외쳐주세요.\n\"너무 멋져요 지민님!\"',
    },
];

const Media = {
    content_1: '씨드업의 가장 최신 소식을 받고 싶으시다면,',
    content_2: '미디어키트를 다운로드 해주세요!',
    button: '다운로드',
    action: '이메일을 보내주시면 미디어키트를 보내드립니다.',
};

const Contact = {
    title: {
        subtitle: '씨드업은 여러분의 의견을 기다립니다.',
        address: '주소',
        tel: '연락처',
        email: '이메일',
        name: '이름',
        belong: '소속',
        phone: '연락처',
        content: '내용',
        send: '보내기',
    },
    address: '대구광역시 북구 연암로 40 201동 401호 (주) 씨드업 (대구광역시청별관, 산격동)',
    phone: '053-211-9800',
    email: 'contact@ceedup.com',
    facebook: 'https://www.facebook.com',
    twitter: 'https://www.instagram.com',
    youtube: 'https://www.youtube.com',
};

const Term = {
    title: '개인정보 수집/이용 항목 및 목적(필수)',
    subtitle: '(주) 씨드업은 기업/단체 및 개인의 정보 수집 및 이용 등 처리에 있어 아래의 사항을 관계법령에 따라 아래와 같이 고지하고 안내해 드립니다.',
    private: '1. 정보 수집의 이용 목적 : 보도자료, 회사 정보 제공 및 관련 상담\n\n2. 수집/이용 항목 : 이름(담당자명), 회사/단체명, 이메일, 연락처\n\n3. 보유 및 이용기간 : 상담 종료 후 6개월, 정보제공자의 삭제 요청 시 즉시 삭제\n\n4. 개인정보처리담당 : 전화 053-211-9800 | 이메일 contact@ceedup.com',
    agree: '동의합니다',
    goback: '돌아가기',
};

const Footer = {
    info_1: '(주)씨드업 | CEEDUP Inc. | 사업자등록번호 : 458-86-01707 | 대표 : 안상용',
    info_2: '대한민국 대구광역시 북구 연암로 40 201동 401호 (대구광역시청별관, 산격동)',
};

export default {
    Header,
    Core_Tech_Title,
    Core_Tech,
    Introduction,
    Heritage_Monitor,
    About,
    Core_Value,
    Services,
    Timeline_Title,
    Timeline,
    Team_Title,
    Team,
    Media,
    Contact,
    Term,
    Footer,
};
