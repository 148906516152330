import React from 'react';

const Introduction = ({data}) => {
    return (
        <header id='header'>
            <div className='intro' style={{backgroundImage: `')`}}>
                <div className='overlay' id='overlay-ceedup'>
                    <div className='container'>
                        <div className='row'>
                            <div className='custom-intro-text'>
                                <div className='introduction-text'>
                                    {data}
                                </div>
                                {/* <div className='introduction-text'>
                                    기술 기반의 혁신을 실현하는 기업입니다.
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Introduction;
