import React from 'react';

const termModal = ({data, onAgreeTerm, closeModal, loading}) => {
    return (
        <div id='term' className='custom-modal-background'>
            <div className='custom-modal-wrapper'>
                <div className='text-wrapper'>
                    <div className='term-modal-title'>{data.title}</div>
                    <div className='term-textbox'>
                        <div className='term-modal-suttitle'>
                            {data.subtitle}
                        </div>
                        {
                            data.private.split('\n').map((line, i) => {
                                return (
                                    <div key={String(i)} className='multi-line-text'>{line}</div>
                                );
                            })
                        }
                    </div>
                    <div className='button-wrapper'>
                        {
                            loading ?
                                <div className='agree-button'>{data.agree}</div> :
                                <div
                                    className='agree-button'
                                    onClick={onAgreeTerm}
                                >
                                    {data.agree}
                                </div>
                        }
                        <div className='disagree-button' onClick={closeModal}>{data.goback}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default termModal;
