import React from 'react';
import {Link} from 'react-router-dom';

const _404 = () => {
    return (
        <React.Fragment>
            <div id='_404'>
                <div className='_404-title'>404</div>
                <div className='_404-text'>페이지를 찾을 수 없습니다</div>
                <Link to='/' className='btn btn-custom btn-lg _404-button'>홈으로 돌아가기</Link>
            </div>
        </React.Fragment>
    );
};

export default _404;
