import validateInput from './validateInput';
import hookToSlack from './hookToSlack';
import requestSendEmail from './requestSendEmail';

const sendMail = (data) => new Promise(async (resolve, reject) => {
    try {
        const {name, email} = data;
        const {validation, log} = validateInput({name, email});

        if (!validation) {
            return {success: false, log};
        }

        await hookToSlack(data);
        await requestSendEmail(data);

        resolve({success: true, log: '감사합니다!'});
    } catch (err) {
        console.log(err);
        reject({success: false, log: '다시 시도해주세요'});
    }
});

export default sendMail;
