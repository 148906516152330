import React from 'react';

const product = (props) => {
    const {
        title, subtitle,
        main_description, description,
        process, process_highlight, functions,
    } = props.data;
    return (
        <div id='product'>
            <div className='container'>
                <div className='product-title-wrapper'>
                    <div className='product-subtitle'>{subtitle}</div>
                    <div className='product-title'>{title}</div>
                </div>
                <img className='product-image' src={require('../assets/product.png')} />
            </div>
            <div className='container greyscale column'>
                <div className='description-title'>
                    <div className='label'>Heritage Monitor</div>
                    <div className='description-wrapper'>
                        <div className='main-description'>{main_description}</div>
                        <div className='description'>{description}</div>
                    </div>
                </div>
                <div className='product-image-wrapper'>
                    <img className='product-image' src={require('../assets/product_2.png')} />
                    <div className='sized-box' />
                    <img className='product-image' src={require('../assets/product_3.png')} />
                </div>
            </div>
            <div className='container column'>
                <div className='functions-title'>
                    {process}
                    <span className='highlight'>{process_highlight}</span>
                </div>
                <div className='functionality-wrapper'>
                    {
                        functions.map((v, i) => {
                            return (
                                <div className='functionality-box' key={String(i)}>
                                    <img className='icon' src={v.icon} />
                                    <div className='title'>{v.title}</div>
                                    <ul className='list'>
                                        <li className='element'>{v.element_1}</li>
                                        <li className='element'>{v.element_2}</li>
                                        <li className='element'>{v.element_3}</li>
                                    </ul>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default product;
