/* eslint-disable max-len */
const Header = {
    title: 'CEEDUP',
    subtitle: 'CEED Everywhere, UP Every Time, CEED UP!',
};

const Core_Tech_Title = 'Core Tech';

const Core_Tech = [
    {
        name: 'AIoT',
        description: 'ㅤ',
        icon: 'img/icons/ai.svg',
    },
    {
        name: 'EDGE COMPUTING',
        description: 'ㅤ',
        icon: 'img/icons/circuits.svg',
    },
    {
        name: 'ULTRA LOW POWER DEVICE',
        description: 'ㅤ',
        icon: 'img/icons/electricity.svg',
    },
    {
        name: 'SENSOR DATA ANALYSIS',
        description: 'ㅤ',
        icon: 'img/icons/settings.svg',
    },
    {
        name: 'INTEGRATED IoT MONITORING',
        description: 'ㅤ',
        icon: 'img/icons/vibration.svg',
    },
];

const Introduction = 'CEEDUP actualizes IT-based innovation to solve social problems.';

const Heritage_Monitor = {
    title: 'HERITAGE MONITOR',
    subtitle: 'Integrated Management Solution for Cultural Heritage',
    main_description: 'Cultural Heritage Management with AIoT Technology and Low Power Device',
    description: '',
    process: 'Installation - Analyze - Live monitoring',
    process_highlight: '3 STEP MANAGEMENT',
    functions: [
        {
            title: 'Wireless Low Power Device',
            icon: 'img/icons/ai.svg',
            element_1: 'Wireless Ultra Low Power Device',
            element_2: 'Simple Installation without power and communication infrastructure',
            element_3: 'Self-powered by solar panel',
        },
        {
            title: 'AIoT-based Security System',
            icon: 'img/icons/electricity.svg',
            element_1: 'Blind Spot Monitoring',
            element_2: 'Live Detection of crime and natural disaster',
            element_3: 'Automatic report by AI',
        },
        {
            title: 'Efficiency in Cultural Heritage Management',
            icon: 'img/icons/analysis.svg',
            element_1: '24/7 Monitoring via Mobile and Web',
            element_2: 'Electric Documentation of everyday condition',
            element_3: 'Systematization of Cultural Heritage Management',
        },
    ],
};

const About = {
    img: 'img/icons/logo_under.png',
    title: 'ABOUT US',
    description_title: 'VISION',
    paragraph_1: 'CEED means ‘go’ in Latin. Our members always seek to develop and progress in any circumstances.',
    paragraph_2: 'We also have a mission to solve social problems through convergence of vibration data and IT.',
};

const Core_Value = {
    description: 'What we believe in',
    HR: 'We are looking forward to joining you!',
    HR_description: 'Someone who leads others to solve problems and desires to develop in his or her field.',
    apply: 'Apply',
};

const Services = [
    {
        icon: 'img/icons/heart.svg',
        name: 'Awareness',
        text: 'We have a critical mind to aware social problems and suggest specific solutions.',
    },
    {
        icon: 'img/icons/innovation.svg',
        name: 'Innovation',
        text: 'We create innovation in order to solve the problems.',
    },
    {
        icon: 'img/icons/star.svg',
        name: 'Expertise',
        text: 'We connect advanced expertise to the solution effectively and efficiently.',
    },
    {
        icon: 'img/icons/sport.svg',
        name: 'Actualization',
        text: 'We actualize with continuous challenges.',
    },
];

const Timeline_Title = 'What we have achieved';

const Timeline = [
    {
        date: '2018. 11',
        content: 'Initiated the 1st design of IoT-based cultural heritage monitoring device',
    },
    {
        date: '2019. 4',
        content: 'Developed MVP of Heritage Monitor',
    },
    {
        date: '2019. 7',
        content: 'Awarded 2019 KT Social Change Maker',
    },
    {
        date: '2019. 8',
        content: 'Selected as a promising Start-Up of 2019 certified by Ministry of Education',
    },
    {
        date: '2019. 9',
        content: 'Awarded SK Innovation Award of 2019 Social Venture Contest',
    },
    {
        date: 'ㅤ',
        content: 'Awarded ICT Smart Device Contest',
    },
    {
        date: '2019. 11',
        content: 'Awarded 2019 C-Star Pitching Contest',
    },
    {
        date: 'ㅤ',
        content: 'Awarded 2019 Cultural Data Contest',
    },
    {
        date: 'ㅤ',
        content: 'Founded CEEDUP Inc.',
    },
    {
        date: '2020. 1',
        content: 'Developed Heritage Monitor',
    },
    {
        date: '2020. 2',
        content: 'Founded the Department of R&D',
    },
    {
        date: 'ㅤ',
        content: 'Certified as Venture Company',
    },
];

const Team_Title = 'The Great and  the Strict';

const Team = [
    {
        img: 'img/team/hdh_a.png',
        img_b: 'img/team/hdh_b.png',
        name: 'Han Donghun',
        job: 'Operator',
        info: '천방지축 어리둥절 빙글빙글 돌아가는 동훈님의 하루♬\n\"한번 사는 인생 하고 싶은 일 하고 살자\"라는 신조를 가슴에 품고 살아가는 동훈님은 얼마전 또 다른 \"하고 싶은 일\"을 찾아냈어요. 바로 동료에게 최고의 동기부여를 선사하고 최상의 퍼포먼스를 끌어내는 일이랍니다. 이 멋진 재미를 포기할 수는 없죠! 마흔 살까지 재미를 마음껏 즐기며 커리어를 쌓을 예정입니다만, 마흔 살부터는 요리하고, 사람들에게 맛있는 요리를 선사하며 여유롭게 살 작정을 하고 있어요. 상상만 해도 기대되는, 그야말로 멋드러진 인생이에요',
    },
    {
        img: 'img/team/wasy_a.png',
        img_b: 'img/team/wasy_b.png',
        name: 'An Soyoung',
        job: 'Manager',
        info: '언젠가 내 이름을 건 극장을 만들겠다는 포부를 가진 연극쟁이 소영님. 누구보다 연극을 사랑하지만 거짓말은 못하겠다는 모순을 견디며 살아가고 있어요. 지금은 밤을 새워 대본을 쓰던 손으로 서류를 써내려가고, 낮을 불태워 연극을 만들던 뜨거운 마음으로 매일 출근하고 있답니다. 스트레스 받을 때 빵을 만든다는 소영님의 또다른 모순은 바로 빵을 먹는 건 좋아하지 않는다는 것! 빵을 맛있게 먹어주는 씨드업 팀원들에게 늘 감사하는 마음을 가지고 있대요. 삶이라는 무대 위를 활보하는 소영님을 응원해주세요',
    },
    // {
    //     img: 'img/team/100_a.png',
    //     img_b: 'img/team/100_b.png',
    //     name: 'Baek Gwangrok',
    //     job: 'FW Engineer',
    //     info: '\"운동하기\"다짐 경력 7년 차, \"일찍 일어나는 새가 되기\"다짐 경력 17년 차\n프로다짐러 광록님은 칠하우스 뮤직과 사랑에 빠진 감성의 제왕이랍니다. 사랑에 죽고 정의에 사는 감성 충만한 삻을 살고 있지만, \"에러는 S/W 문제가 아니다. 내 문제다.\"라는 명언을 남긴 멋진 개발자이기도 하죠. 아주 믿음직스러운, 우리 씨드업의 없어선 안될 인재에요. 일과 감성을 모두 놓칠 수 없는 광록님의 최근 삶의 낙은 넷플릭스라고 하네요. 역시 개발로 뜨거워진 머리는 좀비로 식혀주는 게 제맛인 걸까요?',
    // },
    // {
    //     img: 'img/team/gd_a.png',
    //     img_b: 'img/team/gd_b.png',
    //     name: 'Park Gideok',
    //     job: 'Developer',
    //     info: '희노애락, 기덕님에게는 어울리지 않는 단어죠. 감정 기복률 土5%만을 허용하는 기덕님은 탄력 근무제를 운영하는 씨드업에서 가장 일찍일어나는 새를 담당하고 있어요. 언제나 그 자리에서, 그 모습으로 개발을 하는 기덕님을 보며 모두가 안정감을 느낀답니다. 우리의 정신적 지주라고나 할까요? 심지어 퇴근 후 개인시간에 자기계발에 집중하는 숨은 노력가랍니다. 조용히 꺼지지 않는 촛불처럼, 용의주도한 에너자이저 기덕님의 앞날이 기대되는 건 당연지사 명약관화 빼박캔트죠',
    // },
    {
        img: 'img/team/asy_a.png',
        img_b: 'img/team/asy_b.png',
        name: 'An Sangyong',
        job: 'Founder',
        info: '고고학을 공부한 상용님은 전세계를 누비며 보물을 모으는 인디아나 존스가 되려고 했어요. 하지만 세계에 첫 발을 내딛을 첫 이동수단으로 푸드트럭을 선택하고는, 그만 스타트업의 매력에 홀라당 빠져버렸죠. 지금은 매일매일 커피의 힘을 빌려 씨드업을 이끌어나가고 있어요. 밤낮을 기다려 만난 최고의 동료들과 함께 말이에요! 혼자만의 힘으로는 헤쳐나가기 힘든 스타트업 세계를 정복하기 위해 모두의 힘을 빌려 발걸음을 내딛는 상용님. 오늘도 빌리고, 모으고, 줍고, 끌어오고, 만들고... 아무튼 어제보다 나은 내일을 향해 힘차게 나아가고 있답니다',
    },
    // {
    //     img: 'img/team/ksm_a.png',
    //     img_b: 'img/team/ksm_b.png',
    //     name: 'Kim Sangmin',
    //     job: 'FW Engineer',
    //     info: '상민님은 자타공인 우리의 엄격한 관리자에요. 가끔은 내로남불을 시전하지만, 마음속에는 따스함을 지닌 외강내유의 소유자입니다. 세상 쿨한 표정을 한 상민님이 사실은 누구보다 오래 회사에 남아 개발에 몰두하는 열정맨이라는 걸 모르는 사람은 없을거에요. 아닌척, 모르는척, 잔소리하며 씨드업 팀원을 챙기는 엄마같은 존재랍니다. 검은 머리 외국인이 되겠다며 큰소리 치는 여행광답게 자유자재로 구사하는 외국어도 한가득인 재능꾼이죠.\n 다만 서울말은 못해요(쉿)',
    // },
    {
        img: 'img/team/hjm_a.png',
        img_b: 'img/team/hjm_b.png',
        name: 'Han Jimin',
        job: 'Archaeologist',
        info: '차분한 원칙주의자 지민님의 가장 큰 행복은 구체적인 계획을 훌륭히 달성해 나가는 것이에요. 가끔은 몽상가를 꿈꾸기도 하지만, 결국은 실제적인 경험과 지식을 좇게되는 못말리는 고고학 덕후죠. 좌우명인 \"넓지 않아도 좁고 깊게\"는 지민님이 사랑하는 것들에 집중하게 해주는 원동력이 되어 준답니다. 지적으로 깊은 사람이 되기를 바라는, 하지만 이미 그보다 깊은 마음을 가진 지민님을 누구나 좋아하게 될거에요.\n일관성 있는 차분함을 자랑하는 지민님을 만나게 되면 신나게 외쳐주세요.\n\"너무 멋져요 지민님!\"',
    },
];

const Media = {
    content_1: 'Do you want the latest news of CEEDUP?',
    content_2: 'Download our press kit!',
    button: 'DOWNLOAD',
    action: 'Please fill out the form, and we will send you the press kit!',
};

const Contact = {
    title: {
        subtitle: 'We are pleased with your opinion!',
        address: 'Address',
        tel: 'TEL',
        email: 'Email',
        name: 'Name(Contact information name)',
        belong: 'Company/Organization',
        phone: 'Phone Number',
        content: 'Content',
        send: 'Send',
    },
    address: '4F, 201-Dong, 40, Yeonam-ro, Buk-gu, Daegu, Republic of Korea',
    phone: '+82-53-211-9800',
    email: 'contact@ceedup.com',
    facebook: 'https://www.facebook.com',
    twitter: 'https://www.instagram.com',
    youtube: 'https://www.youtube.com',
};

const Term = {
    title: 'Collection and Use of Personal Information Items and Purpose (REQUIRED)',
    subtitle: 'CEEDUP Inc. will notify you of the following matters related to the collection and use of information by firms, companies, organizations, and individuals by relevant laws and regulations as described below.',
    private: '1. Purpose of information collected: Reference to media kit, Inquiry of products and services.\n\n2. Collection and usage of items: Name(Contact information name), email, phone number, and content\n\n3. Eligibility and term of use: Request for deletion of information provision for 6 months after consultation.\n\n4. Contact: +82-53-211-9800 | contact@ceedup.com',
    agree: 'Accept',
    goback: 'Back',
};

const Footer = {
    info_1: 'CEEDUP Inc. | Company Registration Number : 458-86-01707 | CEO : Sang Yong An',
    info_2: '4F, 201-Dong, 40, Yeonam-ro, Buk-gu, Daegu, Republic of Korea',
};

export default {
    Header,
    Core_Tech_Title,
    Core_Tech,
    Introduction,
    Heritage_Monitor,
    About,
    Core_Value,
    Services,
    Timeline_Title,
    Timeline,
    Team_Title,
    Team,
    Media,
    Contact,
    Term,
    Footer,
};
