const validateInput = ({name, email}) => {
    if (!name) {
        return {validation: false, log: '이름을 입력해주세요'};
    }

    if (name.length < 2 || name.length > 30) {
        return {validation: false, log: '이름을 다시 확인해주세요'};
    }

    if (!email) {
        return {validation: false, log: '이메일을 입력해주세요'};
    }

    if (!email.match(/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/)) {
        return {validation: false, log: '이메일 형식과 다릅니다'};
    }

    return {validation: true, log: 'ok'};
};

export default validateInput;
