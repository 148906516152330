import React from 'react';

const modal = ({data, controlModal}) => {
    return (
        <div className='custom-modal-background' onClick={controlModal()}>
            <div className='custom-modal-wrapper'>
                <div className='thumbnail-wrapper'>
                    <img
                        className='thumbnail'
                        src={data.img_b ? data.img_b : require('../assets/logo_under.png')}
                        alt='thumbnail'
                    />
                </div>
                <div className='text-wrapper'>
                    <div className='title'>{data.name}</div>
                    <div className='subtitle'>{data.job}</div>
                    <div className='content'>{data.info}</div>
                </div>
            </div>
        </div>
    );
};

export default modal;
