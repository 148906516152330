import React, {useState} from 'react';

import Modal from './modal';

const Team = (props) => {
    const [modal_state, setState] = useState(false);
    const [team_data, setData] = useState({image: '', name: '', job: '', info: ''});

    const controlModal = (data) => ({}) => {
        setData(data);
        setState(!modal_state);
    };

    return (
        <div id='team' className='text-center'>
            {
                modal_state ?
                    <Modal controlModal={controlModal} data={team_data} /> :
                    undefined
            }
            <div className='container'>
                <div className='col-md-8 section-title'>
                    <h2>Team</h2>
                    <p>{props.title}</p>
                </div>
                <div className='collection-wrapper'>
                    <div className='collection'>
                        {props.data ? props.data.map((v, i) =>
                            <div className='thumbnail' key={String(i)} onClick={controlModal(v)}>
                                <img src={v.img} alt='thumbnail' className='team-img' />
                                <div className='overlay'>
                                    <div className='overlay-text-name'>{v.name}</div>
                                    <div className='overlay-text-job'>{v.job}</div>
                                </div>
                            </div>,
                        ) : 'loading'}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Team;
