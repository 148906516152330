import React from 'react';

const imageDescription = (props) => {
    return (
        <div className='about'>
            <div className='container'>
                <div id='vision' className='row'>
                    <img
                        src={props.data.img}
                        className='logo-image-responsive'
                        alt='ceedup-logo'
                    />
                    <p>{props.data.description}</p>
                    <div className='about-text'>
                        <h2>{props.data.description_title}</h2>
                        <p>{props.data.paragraph_1}</p>
                        <p>{props.data.paragraph_2}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default imageDescription;
