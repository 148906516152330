import React, {useEffect} from 'react';

import Header from '../components/header';
import CoreTech from '../components/coreTech';
import Introduction from '../components/introduction';
import Product from '../components/product';

const Home = ({data}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <React.Fragment>
            <Header data={data.Header} id='overlay-intro' />
            <CoreTech data={data.Core_Tech} title={data.Core_Tech_Title} />
            <Introduction data={data.Introduction} />
            <Product data={data.Heritage_Monitor} />
            {/* <Product data={data.Vibe_Monitor} type='distict'/> */}
        </React.Fragment>
    );
};

export default Home;
