import React from 'react';

const Footer = ({data}) => {
    return (
        <div id='footer'>
            <div className='container'>
                <img src={require('../assets/logo_under.png')} alt='logo' className='logo-small'/>
                <div className='marginer-vertical-10'/>
                <p>{data.info_1}</p>
                <p>{data.info_2}</p>
                <p>
                    <span className='text-bold'>T </span>
                    053-211-9800 |
                    <span className='text-bold'> E </span>
                    contact@ceedup.com
                </p>
                <p>COPYRIGHT {new Date().getFullYear()} &copy; CEEDUP Inc. All rights reserved</p>
            </div>
        </div>
    );
};

export default Footer;
