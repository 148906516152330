import React from 'react';

const Header = (props) => {
    return (
        <header id="header">
            <div className="intro">
                <div
                    className="overlay"
                    id={props.id}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 intro-text">
                                <h1
                                    style={{fontSize: props.fontSize}}
                                >
                                    {props.data ? props.data.title : 'Title'}
                                </h1>
                                {
                                    props.data ?
                                        <p>{props.data.subtitle}</p> :
                                        undefined
                                }
                                {
                                    props.button ?
                                        <a
                                            className="btn btn-custom btn-lg page-scroll"
                                        >
                                            Learn More
                                        </a> :
                                        undefined
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
