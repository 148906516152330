import React, {useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
// import $ from 'jquery';

import './configs/custom.scss';

import Home from './screens/Home';
import Contact from './screens/Contact';
import About from './screens/About';
import _404 from './screens/404';

import Navigation from './components/navigation';
import Footer from './components/footer';

import data_kr from './configs/data_kr';
import data_en from './configs/data_en';

const App = () => {
    // const [data, setData] = useState({});
    const [localization, setLocalization] = useState('ko');

    const handleLocalization = (local) => () => {
        setLocalization(local);
    };

    // useEffect(() => {
    //     $.ajax({
    //         url: '/data.json',
    //         dataType: 'json',
    //         cache: false,
    //         success: (dataGot) => {
    //             setData(dataGot);
    //         },
    //         error: function(xhr, status, err) {
    //             console.log(err);
    //             alert(err);
    //         },
    //     });
    // });

    return (
        <Router>
            <Switch>
                <Route exact path='/'>
                    <Navigation handleLocalization={handleLocalization} />
                    <Home data={localization === 'ko' ? data_kr : data_en} />
                    <Footer data={localization === 'ko' ? data_kr.Footer : data_en.Footer} />
                </Route>
                <Route exact path='/contact'>
                    <Navigation handleLocalization={handleLocalization} />
                    <Contact data={localization === 'ko' ? data_kr : data_en} />
                    <Footer data={localization === 'ko' ? data_kr.Footer : data_en.Footer} />
                </Route>
                <Route exact path='/about'>
                    <Navigation handleLocalization={handleLocalization} />
                    <About data={localization === 'ko' ? data_kr : data_en} />
                    <Footer data={localization === 'ko' ? data_kr.Footer : data_en.Footer} />
                </Route>
                <Route>
                    <_404 />
                </Route>
            </Switch>
        </Router>
    );
};

export default App;
